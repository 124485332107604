import React from "react";
import { motion } from "framer-motion";
import Navigation from "../components/Navigation";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Footer from "../components/footer";

import CookieConsent from "react-cookie-consent";

import HeaderImg from "../images/main_banner.jpg";
import CovidBannner from "../images/banner.png";
import GroundImg from "../images/ground.jpg";
import ProcessImg from "../images/process.jpg";
import GatewayImg from "../images/gatewaynew.jpg";
import P2pImg from "../images/p2pnetwork.jpg";
import VisaImg from "../images/visanew.jpg";
import Profit from "../images/profit.png";
import Identity from "../images/identity.jpg";

const IndexPage = () => (
  <Layout>
    <SEO title="SLS Panamá" />

    <div
      className="top_header pb-20"
      style={{
        backgroundImage: `url(${HeaderImg})`,
        backgroundColor: `#ddd`,
        backgroundSize: `cover`
      }}
    >
      <Navigation />

      <div className="w-full lg:flex">
        <motion.div
          className="w-full lg:w-2/5 ml-0 lg:ml-20 lg:mt-10 mt-10 "
          id="covidBanner text-center"
          initial={{ scale: 0.2 }}
          animate={{ scale: 0.8 }}
          transition={{ duration: 1.2 }}
        >
          <img
            src={CovidBannner}
            className="mx-auto"
            style={{ width: "90%" }}
          />
        </motion.div>
        {/* NAVIGATION START */}
        <div className="w-10/12 lg:w-3/5 ml-5 lg:ml-20">
          <ul class="minerva uppercase text-3xl text-white list-none lg:list-disc lg:list-inside mt-20">
            <motion.li
              initial={{ x: "-250vh" }}
              animate={{ x: 0 }}
              transition={{ delay: 1.1 }}
              className="mt-5 "
            >
              <a
                href="#iiv"
                style={{ color: `#D39983` }}
                className="hover:underline"
              >
                PREMIUM IDENTITY (ONBOARDING) VERIFICATION
              </a>
            </motion.li>
            <motion.li
              initial={{ x: "-250vh" }}
              animate={{ x: 0 }}
              transition={{ delay: 1.4 }}
              className="mt-5"
            >
              <a
                href="#dnm"
                style={{ color: `#D39983` }}
                className="hover:underline"
              >
                Data network management
              </a>
            </motion.li>
            <motion.li
              initial={{ x: "-250vh" }}
              animate={{ x: 0 }}
              transition={{ delay: 1.6 }}
              className="mt-5 "
            >
              <a
                href="#wph"
                style={{ color: `#D39983` }}
                className="hover:underline"
              >
                Web Portal Hosting
              </a>{" "}
              <span style={{ color: "#D39983" }}>AND</span>{" "}
              <a
                href="#wph"
                style={{ color: `#D39983` }}
                className="hover:underline"
              >
                DEVELOPMENT
              </a>
            </motion.li>
            <motion.li
              initial={{ x: "-250vh", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 1.8 }}
              className="mt-5"
            >
              <a
                href="#wbd"
                style={{ color: `#D39983` }}
                className="hover:underline"
              >
                Web Back End Development
              </a>
            </motion.li>
            <motion.li
              initial={{ x: "-250vh" }}
              animate={{ x: 0 }}
              transition={{ delay: 2 }}
              className="mt-5"
            >
              <a
                href="#itm"
                style={{ color: `#D39983` }}
                className="hover:underline"
              >
                Information Token Management
              </a>
            </motion.li>

            <motion.li
              initial={{ x: "-250vh" }}
              animate={{ x: 0 }}
              transition={{ delay: 2.2 }}
              className="mt-5 "
            >
              <a
                href="#visa"
                style={{ color: `#D39983` }}
                className="hover:underline"
              >
                VISA CARD
              </a>
            </motion.li>
          </ul>
        </div>
      </div>
    </div>
    {/* INDENTITY start */}
    <div
      className="ground w-full lg:flex-row-reverse lg:flex"
      style={{
        backgroundImage: `url(${Identity})`,
        backgroundColor: `#222`,
        backgroundSize: `cover`,
        paddingTop: "60px",
        paddingBottom: "60px"
      }}
    >
      <div className="copy mx-auto lg:w-3/5 ">
        <h2
          className="minerva uppercase text-3xl lg:text-5xl  text-white ml-5 lg:ml-5"
          id="iiv"
        >
          PREMIUM IDENTITY (ONBOARDING) VERIFICATION
        </h2>
        <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-5 ml-5 mr-5">
          Secure & accurate network for assessing the trustworthiness of
          participants. Identity verification is at the forefront of the digital
          platforms revolution. It requires Identity Verification and
          Authentication (ONBOARDING) services. We ensure our customers and it’s
          regulators that its users provide information that is associated with
          their identity; the user is a real person, who is who claims to be and
          is where she or he is.
        </p>

        <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-5 ml-5 mr-5">
          Customized Compliance Solutions; AML, KYC, per activity and or
          jurisdiction. ID, Proof of address, age, geolocation, fingerprint and
          more. One time, person to person back and forth or Regulated
          Compliance Departments in need of thorough documented verification SLS
          Identity Verification and Authentication Network is The Tool.
        </p>
      </div>
    </div>
    {/* DATA NETWORK START */}
    <div className="w-full">
      <div
        className="ground w-full "
        style={{
          backgroundImage: `url(${GroundImg})`,
          backgroundColor: `#222`,
          backgroundSize: `cover`,
          backgroundPosition: `right`,
          paddingTop: "60px",
          paddingBottom: "60px"
        }}
      >
        <div className="copy lg:w-3/5 mx-auto">
          <h2
            className="minerva uppercase text-3xl lg:text-5xl text-white ml-5 lg:ml-5"
            id="dnm"
          >
            Data network management
          </h2>
          <ul className="minerva text-2xl lg:text-3xl text-white list-none lg:list-disc lg:list-inside mt-10 lg:mt-20 ml-5 lg:ml-5">
            <li>Domain Registration</li>
            <li>Web Development</li>
            <li>Email Deployment</li>
            <li>Security</li>
            <li>Order Fulfillment</li>
            <li>Inventory Management</li>
            <li>Tracking</li>
            <li>Customer Service</li>
            <li>Search Engine Optimization (SEO)</li>
            <li>Advertising and Marketing</li>
          </ul>
        </div>
      </div>
      {/* WEB PORTAL HOSTING START */}
      <div className="w-full">
        <div
          className="ground w-full  lg:flex lg:flex-row-reverse text-right"
          style={{
            backgroundImage: `url(${ProcessImg})`,
            backgroundColor: `#222`,
            backgroundSize: `cover`,
            backgroundPosition: `right`,
            paddingTop: "60px",
            paddingBottom: "60px"
          }}
        >
          <div className="copy lg:w-3/5 mx-auto text-left">
            <h2
              className="minerva uppercase text-3xl lg:text-5xl text-white ml-5 lg:ml-0"
              id="wph"
            >
              WEB PORTAL HOSTING AND DEVELOPMENT
            </h2>

            <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-1 ml-5 mr-5">
              SLS Applies Information Technologies to Transmit Process and Store
              Information to Automate ecommerce Administration.
            </p>

            <ul className="minerva text-2xl lg:text-3xl text-white list-none lg:list-disc lg:list-inside mt-10 lg:mt-20">
              <li>Customer Registration. (Database Management)</li>

              <li>Administrative Users. (Front End)</li>

              <li>Order Management. (Back End) </li>

              <li>Payroll and Purchase Orders. (Back End)</li>
            </ul>

            <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-1 ml-5 mr-5">
              The SLS Automation Developers accomplish tasks which once required
              direct human guidance with multiple digital processes, securely
              sharing information between systems.
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* WEB BACKEND DEVELOPMENT */}
    <div className="w-full">
      <div
        className="ground w-full"
        style={{
          backgroundImage: `url(${GatewayImg})`,
          backgroundColor: `#222`,
          backgroundSize: `cover`,
          paddingTop: "60px",
          paddingBottom: "60px"
        }}
      >
        <div className="copy mx-auto lg:w-3/5">
          <h2
            className="minerva uppercase text-3xl lg:text-5xl text-white ml-5 lg:ml-5"
            id="wbd"
          >
            Web Back End Development
          </h2>
          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-5 ml-5 mr-5">
            Users can be reached globally, everyone can easily connect sell or
            buy all around the world. However for Individuals and Corporations,
            the “online transaction” constitutes a Roadblock.
          </p>

          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-5 ml-5 mr-5">
            The maze of : Back End, Payment Network, Payment Processor, POS,
            Payment Network Provider, P2P, P2B, Financial KYC, M-Commerce,
            F-Commerce, B2A, C2A, 301 Redirect, AML Policies, PCI, PCISSC, API,
            ATS etc etc etc, is difficult to navigate .
          </p>

          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-5 ml-5 mr-5">
            Ainely S.A. (Uruguay) , part of the SLS Family, integrated the tools
            to sail trough the maze our Golden Gate Application Programming
            Interface<sup>®</sup>.
          </p>

          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-5 ml-5 mr-5">
            With it, SLS S.A. Provides a Network environment to transmit,
            execute and store eCommerce information, to either Clear with
            Acquirers or with its own customized PNP.
          </p>
        </div>
      </div>
      {/* INFORMATION TOKEN MANAGEMENT */}
      <div className="w-full">
        <div
          className="ground w-full lg:flex lg:flex-row-reverse"
          style={{
            backgroundImage: `url(${P2pImg})`,
            backgroundColor: `#222`,
            backgroundSize: `cover`,
            backgroundPosition: `bottom`,
            paddingTop: "80px",
            paddingBottom: "80px"
          }}
        >
          <div className="copy lg:w-3/5 mx-auto">
            <h2
              className="minerva uppercase text-3xl lg:text-5xl text-white ml-5 lg:ml-0"
              id="itm"
            >
              Information Token Management
            </h2>
            <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-1 ml-5 mr-5">
              The E-Brick a Collaborative Ecosystem SLS Manages Data for Peer to
              Peer cross-check verification for:
            </p>

            <ul class="minerva  text-2xl text-white list-none lg:list-disc lg:list-inside mt-20">
              <li>3L Third Party Logistics Provider 3PL</li>

              <li>BM Bookkeeper-Mentor</li>

              <li>CI PCI Security Standards Council Certified Individual</li>

              <li>CM Counsel-Mentor</li>

              <li>IS Individual Service Provider</li>

              <li>SA System Administrator</li>

              <li>SD Software Developer</li>

              <li>SE Cyber Security Expert</li>

              <li>SM Social Media Manager</li>

              <li>SO S.E.O Specialist </li>

              <li>ST Brick-and-click Store</li>

              <li>WD Web Developers</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    {/* VISA CARD */}
    <div className="w-full">
      <div
        className="ground w-full lg:flex lg:flex-row-reverse"
        style={{
          backgroundImage: `url(${VisaImg})`,
          backgroundColor: `#222`,
          backgroundSize: `cover`,
          packgroundPosition: "left",
          paddingTop: "60px",
          paddingBottom: "60px"
        }}
      >
        <div className="w-full lg:w-1/3 lg:mr-40">
          <h2 className="minerva text-3xl lg:text-5xl text-white mr-5 ml-5">
            <a href="https://frontend.ebricknetwork.com/register/">
              <img src={Profit} alt="Profit Visa Card" style={{}} />
            </a>
          </h2>
        </div>
        <div className="w-full lg:w-2/5 lg:ml-20">
          <h2
            className="minerva uppercase text-3xl lg:text-5xl text-white ml-5 lg:ml-0"
            id="visa"
          >
            Visa<sup>®</sup> card
          </h2>
          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-1 ml-5">
            SLS with its strategic allies; FC Medios de Pago, ProfitPro Versatec
            and Visa<sup>®</sup> can provide registered users* with VISA
            <sup>®</sup> Card Solutions. KYC Clear Fees to registered
            cardholders, Access to Balance and Fees on line.
          </p>

          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-1 ml-5">
            *All cardholders must comply KYC and Origins of Funds Policies and
            Procedures, with verified or verifiable information.
          </p>
          <a
            className="inline-block text-lg text-white bg-blue-900 hover:bg-blue-600 rounded-md px-4 py-2 mt-3 border border-black"
            href="https://frontend.ebricknetwork.com/register/"
          >
            Request your card here
          </a>
        </div>
      </div>
    </div>
    <Footer />
    <CookieConsent
      location="bottom"
      buttonText="ACCEPT"
      cookieName="conscentcookie"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
    >
      This website uses cookies to enhance the user experience.{" "}
      <span style={{ fontSize: "10px" }}>No personal data is stored.</span>
    </CookieConsent>
    <div
      className="extra"
      style={{
        color: `#2D3749`,
        overflow: `hidden`,
        maxHeight: `40px`,
        backgroundColor: `#2D3749`
      }}
    >
      <p>e-Commerce from the Ground Up</p>

      <p>
        Domain Registration Web Development Email Deployment Security Payment
        Processing Order Fulfillment Inventory Management Tracking Customer
        Service Search Engine Optimization (SEO) Advertising and Marketing
      </p>

      <p>Process Automation</p>

      <p>
        SLS Applies Information Technologies to Transmit Process and Store
        Information to Automate eCommerce Administration.
      </p>

      <p>
        Customer Registration. (Database Management) Administrative Users.
        (Front End) Order Management. (Back End) Payroll and Purchase Orders.
        (Back End)
      </p>

      <p>
        The SLS Automation Developers accomplish tasks which once required
        direct human guidance with multiple digital processes, securely sharing
        information between systems.
      </p>
      <p>SECURE online gateway services</p>

      <p>
        Users can be reached globally, everyone can easily sell or buy all
        around the world, for individuals and corporations online transaction
        can be difficult to settle.
      </p>

      <p>
        Security is important, to avoid fraud and protect and manage
        information.
      </p>

      <p>
        Ainely S.A. (Uruguay) , part of the SLS Family, integrated the tools
        required in the form of Golden Gate Application Programming Interface®.
      </p>

      <p>
        SLS S.A. Provides a Gateway Software for e-Commerce, To Clear the Sell
        and Refunds on the PNP Acquirers or develop a customized PNP.
      </p>
      <p>Peer-to-Peer Network “P2P”</p>

      <p>
        SLS EBRICK.® Our Payment Network for P2P transactions. Powered by
        Ainely´s Golden Gate Application Programming Interface® allows
        individuals to access the convenience of E-commerce to Clear the Sales
        and Refunds on major PNP´s. (Visa-MC)
      </p>

      <p>Visa® card</p>

      <p>
        SLS with its strategic allies; FC Medios de Pago, ProfitPro Versatec and
        Visa® can provide registered users* with VISA® Card Solutions. KYC Clear
        Fees to registered cardholders, Access to Balance and Fees on line.
      </p>

      <p>
        *All cardholders must comply KYC and Origins of Funds Policies and
        Procedures, with verified or verifiable information.
      </p>
    </div>
  </Layout>
);

export default IndexPage;
